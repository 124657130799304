import { Box } from "@mui/material";
import React from "react";

function VaultSvg({ stroke }) {
  return (
    <Box sx={{ stroke: `${stroke}` }}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        // eslint-disable-next-line react/react-in-jsx-scope
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="auto"
        viewBox="0 0 53 53"
        style={{ enableBackground: "new 0 0 53 53" }}
        preserveAspectRatio="xMinYMin meet"
        xmlSpace="preserve"
      >
        <g>
          <path
            d="M4.2410278,46.5144958h3.414978v1.6140137c0,0.7039795,0.572998,1.2769775,1.276001,1.2769775h3.8220215
                        c0.7039795,0,1.2769775-0.572998,1.2769775-1.2769775v-1.6140137h24.9379883v1.6140137
                        c0,0.7039795,0.572998,1.2769775,1.2769775,1.2769775h3.8210449c0.7039795,0,1.2769775-0.572998,1.2769775-1.2769775v-1.6140137
                        h3.414978c1.1200562,0,2.0310059-0.9109802,2.0310059-2.0310059V5.6254883c0-1.1199951-0.9109497-2.0309753-2.0310059-2.0309753
                        H4.2410278c-1.1200562,0-2.0310059,0.9109802-2.0310059,2.0309753V44.48349
                        C2.210022,45.6035156,3.1209717,46.5144958,4.2410278,46.5144958 M12.9869995,48.1285095
                        c0,0.1279907-0.1049805,0.2330017-0.2329712,0.2330017H8.9320068c-0.1279907,0-0.2319946-0.105011-0.2319946-0.2330017v-1.6130066
                        h4.2869873V48.1285095z M44.2999878,48.1285095c0,0.1279907-0.1040039,0.2330017-0.2329712,0.2330017h-3.8210449
                        c-0.1289673,0-0.2329712-0.105011-0.2329712-0.2330017v-1.6130066h4.2869873V48.1285095z M3.2540283,5.6254883
                        c0-0.5439758,0.4419556-0.9869995,0.9869995-0.9869995h44.5179443c0.5440063,0,0.9869995,0.4430237,0.9869995,0.9869995V44.48349
                        c0,0.5440063-0.4429932,0.9869995-0.9869995,0.9869995H4.2410278c-0.5450439,0-0.9869995-0.4429932-0.9869995-0.9869995V5.6254883z
                        "
          />
          <path
            d="M5.7568359,39.5643921v3.0500183c0,0.2879944,0.2340088,0.5220032,0.5220337,0.5220032h40.4420166
                        c0.2879639,0,0.5219727-0.2340088,0.5219727-0.5220032V7.4944153c0-0.2880249-0.2340088-0.5220032-0.5219727-0.5220032H6.2788696
                        c-0.2880249,0-0.5220337,0.2339783-0.5220337,0.5220032v3.0499878c-0.6619873,0.04599-1.1919556,0.5849915-1.1919556,1.2590027
                        v4.3840027c0,0.6739807,0.5299683,1.2139893,1.1919556,1.2599792v15.2140198
                        c-0.6619873,0.04599-1.1919556,0.5859985-1.1919556,1.2599792v4.3840027
                        C4.5648804,38.9794006,5.0948486,39.5184021,5.7568359,39.5643921 M46.1988525,8.0163879v34.0760193H6.8008423v-2.53302
                        c0.6500244-0.0579834,1.1680298-0.5889893,1.1680298-1.2539978v-4.3840027c0-0.6659851-0.5180054-1.196991-1.1680298-1.2549744
                        v-15.223999c0.6500244-0.0580139,1.1680298-0.5890198,1.1680298-1.2550049v-4.3840027
                        c0-0.6650085-0.5180054-1.1960144-1.1680298-1.2539978v-2.53302H46.1988525z M5.6088867,16.1874084v-4.3840027
                        c0-0.1279907,0.1039429-0.2319946,0.2319946-0.2319946h0.8509521c0.1290283,0,0.2330322,0.1040039,0.2330322,0.2319946v4.3840027
                        c0,0.1289978-0.1040039,0.2330017-0.2330322,0.2330017H5.8408813C5.7128296,16.4204102,5.6088867,16.3164063,5.6088867,16.1874084
                         M5.6088867,33.9213867c0-0.1279907,0.1039429-0.2329712,0.2319946-0.2329712h0.8509521
                        c0.1290283,0,0.2330322,0.1049805,0.2330322,0.2329712v4.3840027c0,0.1280212-0.1040039,0.2320251-0.2330322,0.2320251H5.8408813
                        c-0.1280518,0-0.2319946-0.1040039-0.2319946-0.2320251V33.9213867z"
          />
          <path
            d="M18.3777466,20.6911926c-1.0559692,1.5910034-1.6190186,3.4339905-1.6190186,5.3649902
                        c0,1.9360046,0.5630493,3.78302,1.6190186,5.3740234l-1.4760132,1.4759827
                        c-0.3709717,0.3730164-0.5750122,0.8650208-0.572998,1.3869934c0.0029907,0.5169983,0.2070313,1.0020142,0.5700073,1.3590088
                        c0.3699951,0.3770142,0.8630371,0.5840149,1.3850098,0.5840149c0.5200195,0,1.0050049-0.20401,1.3659668-0.5710144
                        l1.4780273-1.4779968c1.6030273,1.0580139,3.4489746,1.6210022,5.3729858,1.6210022
                        c1.9400024,0,3.7820435-0.5629883,5.3649902-1.618988l1.4760132,1.4759827
                        c0.3800049,0.3800049,0.8800049,0.5700073,1.3790283,0.5700073c0.4989624,0,0.9989624-0.1900024,1.3789673-0.5700073v-0.0010071
                        c0.7600098-0.7599792,0.7600098-1.9969788,0-2.7579956l-1.4769897-1.4759827
                        c1.0570068-1.5910034,1.6199951-3.4380188,1.6199951-5.3740234c0-1.9309998-0.5629883-3.7739868-1.6199951-5.3649902
                        l1.4769897-1.4759827c0.757019-0.7580261,0.757019-1.9910278,0-2.7480164
                        c-0.7559814-0.756012-1.9920044-0.7630005-2.756958-0.0100098l-1.4770508,1.4750061
                        c-1.5819702-1.0559998-3.4249878-1.617981-5.3649902-1.617981c-1.9240112,0-3.7699585,0.5629883-5.3739624,1.6199951
                        l-1.4770508-1.4770203c-0.7509766-0.7519836-1.9819946-0.7529907-2.7509766,0.0030212
                        c-0.7639771,0.7789917-0.7630005,1.9879761,0.0029907,2.7550049L18.3777466,20.6911926z M32.1207275,23.1941833
                        c0.4520264,0.8789978,0.6880493,1.8570251,0.6880493,2.8619995c0,1.0110168-0.2360229,1.9920044-0.6880493,2.8720093
                        l-1.8389893-1.8389893l-0.1019897-0.1020203c0.0159912-0.0619812,0.0230103-0.1269836,0.0349731-0.190979
                        c0.0110474-0.0549927,0.0230103-0.1090088,0.0310059-0.1650085c0.0280151-0.1879883,0.0469971-0.3789978,0.0469971-0.5750122
                        c0-0.2879944-0.0409546-0.5599976-0.098999-0.822998c-0.0079956-0.0319824-0.0050049-0.0679932-0.0130005-0.0999756
                        l0.1030273-0.1020203L32.1207275,23.1941833z M20.192749,26.0561829c0-1.0059814,0.2360229-1.9830017,0.6879883-2.8619995
                        l1.9400024,1.9400024c-0.0089722,0.0320129-0.0059814,0.0680237-0.0130005,0.1010132
                        c-0.05896,0.2640076-0.098999,0.5350037-0.098999,0.8209839c0,0.1960144,0.0189819,0.3870239,0.0469971,0.5760193
                        c0.0079956,0.0549927,0.0200195,0.1080017,0.0300293,0.1629944c0.0130005,0.0639954,0.0199585,0.1289978,0.0360107,0.1919861
                        l-1.94104,1.9400024C20.428772,28.0481873,20.192749,27.0671997,20.192749,26.0561829 M25.5737305,29.7412109
                        c0.0570068,0.0149841,0.1190186,0.0219727,0.1790161,0.0349731c0.0540161,0.0110168,0.1099854,0.0230103,0.1660156,0.0310059
                        c0.1870117,0.0299988,0.3779907,0.0490112,0.572998,0.0500183c0.0029907,0,0.0059814,0.0009766,0.0089722,0.0009766
                        c0.0170288,0,0.0320435-0.0039978,0.0490112-0.0039978c0.1740112-0.0019836,0.3469849-0.0180054,0.5170288-0.0429993
                        c0.0619507-0.0090027,0.1199951-0.0209961,0.1799927-0.0329895c0.0579834-0.0110168,0.1189575-0.0169983,0.1759644-0.0320129
                        l1.9400024,1.9400024c-1.7639771,0.9060059-3.9799805,0.8999939-5.7340088,0L25.5737305,29.7412109z M27.4227295,22.3761902
                        c-0.0749512-0.0189819-0.1539917-0.0289917-0.230957-0.0429993c-0.0350342-0.0059814-0.0690308-0.0130005-0.1040039-0.0189819
                        c-0.3920288-0.0599976-0.802002-0.0580139-1.1870117,0.0039978c-0.0480347,0.0069885-0.0930176,0.0169983-0.1389771,0.026001
                        c-0.0630493,0.0130005-0.1280518,0.0209961-0.1880493,0.0369873l-1.9359741-1.9360046
                        c1.7680054-0.9140015,3.9389648-0.9159851,5.7230225-0.0069885L27.4227295,22.3761902z M25.6217651,23.4611816
                        c0.2470093-0.0969849,0.5390015-0.144989,0.8349609-0.1489868c0.0140381-0.0010071,0.026001-0.0039978,0.0390015-0.0039978
                        c0.2670288,0,0.53302,0.0440063,0.7770386,0.1180115c0.0409546,0.0119934,0.0849609,0.0179749,0.125,0.0319824
                        c0.7879639,0.2770081,1.4240112,0.9120178,1.7030029,1.7059937c0.098999,0.2710266,0.1480103,0.5710144,0.1480103,0.8919983
                        c0,0.1590271-0.0120239,0.3150024-0.0370483,0.4670105c-0.026001,0.151001-0.0629883,0.2980042-0.1129761,0.4389954v0.0010071
                        c-0.0009766,0-0.0009766,0.0010071-0.0009766,0.0020142c-0.276001,0.7949829-0.9100342,1.4289856-1.7050171,1.6999817
                        c-0.4130249,0.1499939-0.9169922,0.1860046-1.3619995,0.1069946c-0.1500244-0.0269775-0.2969971-0.0669861-0.4360352-0.1219788
                        c-0.1959839-0.0640259-0.381958-0.1520081-0.5559692-0.2600098c-0.5200195-0.322998-0.927002-0.8280029-1.1359863-1.428009
                        c-0.098999-0.2819824-0.1500244-0.5870056-0.1500244-0.9060059c0-0.3209839,0.0499878-0.6219788,0.1500244-0.8979797
                        C24.1807251,24.3681946,24.8147583,23.7371826,25.6217651,23.4611816 M17.8027344,26.0561829
                        c0-1.6529846,0.4559937-3.2359924,1.3240356-4.6149902l0.9979858,0.9970093
                        c-0.6290283,1.0979919-0.9760132,2.3349915-0.9760132,3.617981c0,1.2880249,0.3469849,2.5290222,0.9760132,3.6270142
                        L19.12677,30.6811829C18.258728,29.3021851,17.8027344,27.71521,17.8027344,26.0561829 M18.9077759,34.9302063
                        c-0.335022,0.3410034-0.9160156,0.3450012-1.2709961-0.0160217c-0.1690063-0.1669922-0.2630005-0.3880005-0.2640381-0.6259766
                        c-0.0009766-0.2409973,0.0939941-0.4700012,0.2670288-0.6430054l1.7799683-1.7799988l1.7200317-1.7200012l2.1170044-2.1170044
                        c0.0059814,0.0090027,0.0149536,0.0169983,0.0209961,0.026001c0.3150024,0.5100098,0.7420044,0.9349976,1.2420044,1.2389832
                        c0.0049438,0.0030212,0.0089722,0.0080261,0.0139771,0.0110168l-2.1220093,2.1229858l-1.7199707,1.7200012c0,0,0,0-0.0010376,0
                        L18.9077759,34.9302063z M21.87677,33.4371948l0.9959717-0.9949951c1.098999,0.6289978,2.3389893,0.9759827,3.6279907,0.9759827
                        c1.28302,0,2.519043-0.3469849,3.618042-0.9759827l0.9979858,0.9979858
                        C28.3857422,35.1691895,24.6697388,35.1851807,21.87677,33.4371948 M35.3617554,34.9271851
                        c-0.3450317,0.3430176-0.9370117,0.3450012-1.2819824,0l-3.4990234-3.4989929c0-0.0010071,0-0.0010071-0.0009766-0.0010071
                        l-2.1160278-2.1169739c0.007019-0.0040283,0.0120239-0.0110168,0.0200195-0.0150146
                        c0.2520142-0.1560059,0.4859619-0.3380127,0.6950073-0.5469971c0.0079956-0.0079956,0.0139771-0.0190125,0.0209961-0.0280151
                        c0.1999512-0.2019958,0.3759766-0.4259949,0.526001-0.6689758c0.0050049-0.0090027,0.0130005-0.0160217,0.0180054-0.0240173
                        l3.8379517,3.8380127c0,0,0,0,0.0010376,0l1.7789917,1.7789917C35.7157593,33.9981995,35.7157593,34.5731812,35.3617554,34.9271851
                         M35.1987305,26.0561829c0,1.6590271-0.4559937,3.2460022-1.3249512,4.625L32.87677,29.6842041
                        c0.6289673-1.1000061,0.9759521-2.3399963,0.9759521-3.6280212c0-1.2829895-0.3469849-2.519989-0.9759521-3.617981
                        l0.9970093-0.9980164C34.7427368,22.8201904,35.1987305,24.4031982,35.1987305,26.0561829 M34.0767212,17.1981812
                        c0.3550415-0.348999,0.9310303-0.3479919,1.2850342,0.006012c0.3569946,0.3569946,0.3569946,0.9160156,0,1.2730103
                        l-1.7789917,1.7799988c-0.0010376,0-0.0010376,0-0.0010376,0l-3.8399658,3.8389893
                        c-0.0050049-0.0079956-0.0130005-0.0140076-0.0180054-0.0230103c-0.3129883-0.5019836-0.7379761-0.9289856-1.2399902-1.2399902
                        c-0.0079956-0.0059814-0.0140381-0.0130005-0.0230103-0.0180054l2.1190186-2.1199951
                        c0.0009766,0,0.0009766-0.0010071,0.0009766-0.0010071L34.0767212,17.1981812z M31.1167603,18.6811829l-1.0010376,1.0010071
                        c-2.230957-1.2539978-5.0179443-1.25-7.2319946,0.0090027l-1.006958-1.0069885
                        C24.6697388,16.9361877,28.3857422,16.953186,31.1167603,18.6811829 M17.6367798,17.1981812
                        c0.1779785-0.1749878,0.4109497-0.26297,0.6429443-0.26297c0.2300415,0,0.4590454,0.0859985,0.632019,0.2599792
                        l1.7789917,1.7789917c0.0010376,0,0.0010376,0.0010071,0.0010376,0.0010071l1.7299805,1.730011l2.1149902,2.1159973
                        c-0.007019,0.0039978-0.0130005,0.0109863-0.0200195,0.0159912c-0.2509766,0.1540222-0.4839478,0.3359985-0.6919556,0.5429993
                        c-0.0130005,0.0119934-0.0209961,0.0290222-0.0339966,0.0420227c-0.1929932,0.196991-0.3650513,0.4139709-0.5120239,0.6489868
                        c-0.0059814,0.0090027-0.0139771,0.0159912-0.0200195,0.0249939l-2.1189575-2.118988l-1.7200317-1.7200012c0,0,0,0,0-0.0010071
                        l-1.7799683-1.7789917C17.2837524,18.1211853,17.284729,17.5561829,17.6367798,17.1981812"
          />
        </g>
      </svg>
    </Box>
  );
}

export default VaultSvg;
